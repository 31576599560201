class DesktopMenu {
  constructor(nav) {
    this.timeoutVal = 125;
    this.nav = nav;
    this.navHandler(nav);
    this.current = null;
    this.activeMenu = null;

  }

  navHandler(selector) {
    let sel = document.querySelector(selector);
    if (!sel) return;
    const menus = sel.querySelectorAll('ul.sub-menu');
    if (menus) {
      menus.forEach( (menu) => {
        menu.addEventListener('mouseenter', (ev) => {
          this.activeMenu = ev.target;
        });
        menu.addEventListener('mouseleave', (ev) => {
          setTimeout( () => {
            this.activeMenu = null;
          }, this.timeoutVal);
        });
      });
    }
    sel.addEventListener('mouseover', (ev) => {
      let el = ev.target;
      if (el.tagName.toLowerCase() === 'a') {
        el = el.parentElement;
      }
      if (!el) return;
      if (el.classList.contains('menu-item-has-children')){
        if (el != this.current) {
          if (this.current) {
            this.current.classList.remove('open');
          }
          if (this.activeMenu) {
            this.activeMenu = null;
          }
          el.classList.add('open');
          this.current = el;
          return;
        }
      }
      if(el.parentElement && (el.parentElement.id == 'menu-navigasjon')) {
        if (this.activeMenu) return;
        if (this.current == el) {
          return;
        }
        if (!this.current) {
          return;
        }
        this.current.classList.remove('open');
        this.current = null;
        return;
      }
    }, {passive: true});

    sel.addEventListener('mouseleave', (ev) => {
      setTimeout( () => {
        if (this.current && !this.activeMenu) {
          this.current.classList.remove('open');
          this.current = null;
        }
      }, this.timeoutVal);
    },{passive:true});
  }
}

export default DesktopMenu;