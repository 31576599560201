import fitvids from './inc/jquery.fitvids.js';
import DesktopMenu from "./inc/desktop-menu.js";

class FocusOnIntersect {
  constructor(intersector, elementToFocus, threshold = 1.0) {
    this.options = {
      root: null,
      rootMargin: "0px",
      threshold: this.threshold,
    };
    this.intersector = document.querySelector(intersector);
    this.elementToFocus = document.querySelector(elementToFocus);
    this.threshold = threshold;
    if (this.intersector && this.elementToFocus) {
      this.observer = new IntersectionObserver(this.handleIntersect, this.options);
      this.observer._el = this.elementToFocus;
      this.observer.observe(this.intersector);
    }
  }

  handleIntersect(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this._el.focus();
      } else {
        this._el.blur();
      }
    });
  }
}

window.addEventListener('DOMContentLoaded', () => {
  let intersector = new FocusOnIntersect('#searchform', '#searchinput', 1);
  let menu = new DesktopMenu('ul#menu-navigasjon');
});

$(document).ready(function() {
  // Target your .container, .wrapper, .post, etc.
  $(".entry-content, .video-content").fitVids();

  // Modify mobile menu
  $("#menu-navigasjon-sm > .menu-item-has-children > a").after("<span class='expand'><img src='/wp-content/themes/ek2019/assets/img/down.svg'></span>");

  // Mobile menu events
  $(".expand").on("click", function() {
    if ($(this).parent().children('.sub-menu').hasClass('open')) {
      $(this).parent().children('.sub-menu').removeClass('open');
      $(this).removeClass('expanded');
    } else {
      $(this).parent().children('.sub-menu').addClass('open');
      $(this).addClass('expanded');
    }
  });

  // Hamburger expander
  $(".menu-container > .sm").on("click", function() {
    if ($('nav > .menu-sm').hasClass('expanded')) {
      $('nav > .menu-sm').removeClass('expanded');
    } else {
      $('nav > .menu-sm').addClass('expanded');
    }
  });

});